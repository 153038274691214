import React from "react";
import { Link } from "react-router-dom";
import { useRouteMatch } from "react-router-dom";
import PropTypes from "prop-types";

const getColor = status => {
  switch (status) {
    case 2:
      return "#DC143C";
    case 1:
      return "#e754a5";
    case 0:
      return "#228B22";
    default:
      break;
  }
};

const TableCard = props => {
  const match = useRouteMatch();

  return (
    <div
      className="tableCard"
      style={{ backgroundColor: getColor(props.status) }}
      onClick={e => props.onClick(e, props.name)}
    >
      <Link
        to={`${match.url}/${props.name}`}
        style={{ textDecoration: "none", color: "black", cursor: "pointer" }}
      >
        <span>Masa {props.name}</span>
      </Link>
    </div>
  );
};

TableCard.propTypes = {
  name: PropTypes.number,
  status: PropTypes.number,
  onClick: PropTypes.func
};

export default TableCard;
