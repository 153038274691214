import api from "./api";

const getTables = async (roomId) => {
    return await new Promise(async (resolve, reject) => {
        // CALL SERVER

        try {
            const result = await api.get(`/table/${roomId}`);
            resolve(result);
        } catch (error) {
            console.log(error);
        }
    })
}

const getRooms = async () => {
    return await new Promise(async (resolve, reject) => {
        try {
            const result = await api.get("/room");
            resolve(result);
        } catch (error) {
            console.log(error);
        }
    })
}

export default {
    getRooms,
    getTables
}