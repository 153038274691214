import React, { Component } from "react";
import { Link } from "react-router-dom";
import LoadingScreen from "../../Common/LoadingScreen";
import RoomCard from "../../Common/RoomCard";
import service from "../../Services/backend";

class Room extends Component {
  state = {
    blocking: true,
    rooms: []
  };

  async componentDidMount() {
    try {
      const { data } = await service.getRooms();
      this.setState({ rooms: data, blocking: false });
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    return (
      <React.Fragment>
        <LoadingScreen blocking={this.state.blocking}>
          <div className="row mb-4">
            {this.state.rooms.map((room, index) => {
              return (
                <div key={index} className="col-6 mb-3">
                  <Link to={`/api/room/${room.roomCode}`}>
                    <RoomCard title={room.roomName} id={room.roomCode} />
                  </Link>
                </div>
              );
            })}
          </div>
        </LoadingScreen>
      </React.Fragment>
    );
  }
}

export default Room;
