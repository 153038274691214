import React from "react";
import BlockUi from "react-block-ui";
import Loader from "react-loaders";
import PropTypes from "prop-types";
import "react-block-ui/style.css";

const LoadingScreen = props => {
  return (
    <BlockUi
      tag="div"
      className="mb-3"
      blocking={props.blocking}
      message="Loading..."
      loader={<Loader active type="line-scale-pulse-out" color="#02a17c" />}
    >
      {props.children}
    </BlockUi>
  );
};

LoadingScreen.propTypes = {
  blocking: PropTypes.bool
};

export default LoadingScreen;
