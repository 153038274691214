import React from "react";
import { Modal, Button } from "react-bootstrap";
import PropTypes from "prop-types";

const CustomModal = props => {
  return (
    <Modal size={props.size} show={props.show} onHide={props.onHide}>
      <Modal.Dialog>
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.children}</Modal.Body>
        <Modal.Footer>
          <Button size={props.size} variant="secondary" onClick={props.onHide}>
            Inchide
          </Button>
          <Button size={props.size} variant="primary" onClick={props.onSave}>
            Salveaza
          </Button>
        </Modal.Footer>
      </Modal.Dialog>
    </Modal>
  );
};

CustomModal.propTypes = {
  title: PropTypes.string,
  size: PropTypes.string,
  show: PropTypes.bool,
  onHide: PropTypes.func,
  onSave: PropTypes.func
};

export default CustomModal;
