import React from 'react';
import { Switch, Route, Redirect } from "react-router-dom";

import Login from "./Components/Login";
import Api from "./Components/Api";

// import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "react-perfect-scrollbar/dist/css/styles.css";

function App() {
  return (
    <div className="App-header">
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/api" component={Api} />
          <Redirect from="/" to="/login" />
        </Switch>
    </div>
  );
}

export default App;
