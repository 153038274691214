import React, { Component } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import LoadingScreen from "../../Common/LoadingScreen";
import TableCard from "../../Common/TableCard";

import service from "../../Services/backend";

class TableList extends Component {
  state = {
    blocking: true,
    roomId: "",
    tables: [],
    allTables: []
  };

  async componentDidMount() {
    const roomId = this.props.location.pathname.split("/")[3];

    try {
      const { data } = await service.getTables(roomId);

      this.setState({ tables: data, allTables: data, roomId, blocking: false });
    } catch (error) {
      console.log(error);
    }
  }

  handleChange = e => {
    const roomId = parseInt(e.currentTarget.value);

    let tables = [...this.state.allTables];

    if (!isNaN(roomId)) {
      tables = tables.filter(table => table.name === roomId);
    }

    this.setState({ tables });
  };

  handleRedirect = (e, name) => {
    console.log(name);
  };

  render() {
    return (
      <LoadingScreen blocking={this.state.blocking}>
        <div id="centered">
          <p>Mese pentru sala {this.state.roomId}</p>
          <input
            type="number"
            placeholder="Introdu numarul mesei..."
            onChange={this.handleChange}
          />
        </div>
        <div id="tables">
          <PerfectScrollbar
            style={{
              height: `calc(100vh - 120px)`,
              width: "calc(100vw-10px)"
            }}
          >
            <div className="row">
              {this.state.tables.map((table, index) => (
                <div key={index} className="col mb-2">
                  <TableCard
                    name={table.name}
                    status={table.status}
                    onClick={this.handleRedirect}
                  />
                </div>
              ))}
            </div>
          </PerfectScrollbar>
        </div>
      </LoadingScreen>
    );
  }
}

export default TableList;
