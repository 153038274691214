import React from "react";
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom";

import NavBar from "./NavBar";
import Room from "./Room";
import TableList from "./TableList";
import Table from "./Table";

const Test = () => <h1>Test</h1>;

const Api = props => {
  const match = useRouteMatch();

  return (
    <div>
      {/* <NavBar /> */}
      <div className="container">
        <Switch>
          <Route
            path={`${match.url}/room/:roomId/:tableId`}
            component={Table}
          />
          <Route path={`${match.url}/room/:roomId`} component={TableList} />
          <Route path={`${match.url}/room`} component={Room} />
          <Redirect from="/api" to={`${match.url}/room`} />
        </Switch>
      </div>
    </div>
  );
};

export default Api;
