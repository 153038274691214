import React, { Component } from "react";
import LoadingScreen from "../../Common/LoadingScreen";
import CustomModal from "../../Common/CustomModal";

class Table extends Component {
  state = {
    tableId: "",
    blocking: true,
    openModal: false,
    items: [{ name: "x" }, { name: "y" }, { name: "z" }]
  };

  componentDidMount() {
    const tableId = this.props.location.pathname.split("/")[4];

    this.setState({ tableId, blocking: false });
  }

  render() {
    return (
      <LoadingScreen blocking={this.state.blocking}>
        <div className="card">
          <div className="card-header">Masa {this.state.tableId}</div>
          <div className="card-body">
            <h5 className="card-title">Comanda curenta</h5>
            <ul className="list-group list-group-flush">
              {this.state.items.map((item, index) => (
                <li key={index} className="list-group-item">
                  {item.name}
                </li>
              ))}
            </ul>
          </div>
          <div className="card-footer">
            <button
              className="btn btn-success btn-pill btn-sm"
              style={{ fontSize: "10px" }}
              onClick={() =>
                this.setState({ openModal: !this.state.openModal })
              }
            >
              Adauga articol
            </button>
          </div>
        </div>
        <CustomModal
          title="Meniu"
          size="sm"
          show={this.state.openModal}
          onHide={() => this.setState({ openModal: !this.state.openModal })}
        />
      </LoadingScreen>
    );
  }
}

export default Table;
