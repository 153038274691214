import React from "react";
import PropTypes from "prop-types";

const RoomCard = props => {
  return (
    <div id="room_card">
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">{props.title}</h5>
          <h6 className="card-subtitle mb-2 text-muted">
            <i className="fa fa-table" />
          </h6>
        </div>
      </div>
    </div>
  );
};

RoomCard.propTypes = {
  title: PropTypes.string
};

export default RoomCard;
