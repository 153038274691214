import React, { Component } from "react";

class Login extends Component {
  state = {};

  handleChange = e => {
    this.setState({ [e.currentTarget.id]: e.currentTarget.value });
  };

  handleSubmit = e => {
    e.preventDefault();

    console.log(this.state);

    window.location.replace("/api");
  };

  render() {
    return (
      <div className="container" id="login_container">
        <form onSubmit={this.handleSubmit}>
          <div className="form-group row">
            <label htmlFor="email" className="col-sm-2 col-form-label">
              Utlizator
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                id="email"
                className="form-control-plaintext"
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="password" className="col-sm-2 col-form-label">
              Parola
            </label>
            <div className="col-sm-10">
              <input
                type="password"
                id="password"
                className="form-control"
                onChange={this.handleChange}
              />
            </div>
          </div>
          <button type="submit" className="btn btn-primary">
            Continua
          </button>
        </form>
      </div>
    );
  }
}

export default Login;
