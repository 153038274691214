import axios from 'axios';

const instance = axios.create({
    // baseURL: "http://localhost:22050",
    baseURL: "https://api.e-node.ro/restaurant"
});

instance.interceptors.request.use(request => {
    request.headers["x-access-token"] = localStorage.getItem("token");
    return request;
})

export default instance;